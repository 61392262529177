body {
  height: 100%;
  padding: 0;
  overflow: auto;
  margin: 0;
  font-family: Roboto;
  background: #f7f7f7;
}

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

main {
  width: 100%;
}

#root {
  height: 100%;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.switch-wrapper {
  position: relative;
  height: 100%;
}

.switch-wrapper.switch-without-search-bar {
  height: 100%;
  overflow: hidden;
}

.switch-wrapper > div {
  position: absolute;
  left: 0;
  right: 0;
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
  /* height: calc(100% - 64px) !important; */
  height: 100%;
}

.switch-wrapper-app {
  position: relative;
  height: 100%;
}

.switch-wrapper-app > div {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
}

.walkthrough-backdrop.show {
  background: rgb(19 57 118);
  display: flex;
}

.walkthrough-backdrop.show > div {
  margin-top: auto !important;
}

img {
  touch-action: manipulation;
}

.ContentPreview .container {
  padding: 8px;
  height: auto;
  max-height: unset;
  margin-bottom: 60px
}

#root.--prevent-animation .switch-wrapper-app > div {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

.walkthrough-modal {
  margin: auto;
}

@media only screen and (min-width: 800px) {
  .container {
    width: 900px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    max-height: 100%;
    height: 100%;
/*     overflow-y: scroll;
    overflow-x: hidden; */
  }

  /* ContentPreview-responsive > .container {
    height: auto;
    max-height: unset;
  } */

  .yAxisCleared {
    overflow-y: hidden !important;
  }

  .xAxisCleared {
    overflow-x: hidden !important;
  }

  .noOverflow {
    overflow: hidden !important;
  }

  .switch-wrapper > div {
    height: 100%;
  }

  .container > div {
    max-height: 100%;
    /* overflow-y: scroll; */
    display: flex;
    flex-direction: column;
  }

  /* .switch-wrapper.switch-wrapper-search {
    height: 100vh;
  } */
}

@media only screen and (max-width: 800px) {
  .switch-wrapper.switch-wrapper-search {
    height: calc(100% - 81px);
  }
}

@media only print {
  .hidden-print {
    display: none !important;
  }
  * {
    overflow: visible !important;
    box-shadow: none !important;
  }
}
